* {
  margin: 0;
  padding: 0;
}

body.standalone {
  margin: 0;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
}

html {
  font-family: "Poppins", sans-serif;
  background-color: #e5e5e5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
